import { Link } from "gatsby"
import React, { useState } from 'react';
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage as Img } from "gatsby-plugin-image"

const MobileMenu = React.lazy(() =>
  import("./HeaderMobile")
)

function Header() {
  const [navigationToggler, setNavigationToggler] = useState(false);

  const isSSR = typeof window === "undefined"

  const handleNavigationToggler = () =>{
    setNavigationToggler(!navigationToggler);
  }

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "favicon.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 80
            layout: CONSTRAINED
          )
        }
      }
    }
  `)

  return (
    <nav className={navigationToggler ? "mi-header is-visible" : "mi-header"}>
        {!isSSR && (
          <React.Suspense fallback={<div />}>
            <MobileMenu  handleNavigationToggler={handleNavigationToggler} navigationToggler={navigationToggler} />
          </React.Suspense>
        )}
        <div className="mi-header-inner">
            <div className="mi-header-image">
                <Link to="/">
                  <Img
                    image={data.file.childImageSharp.gatsbyImageData}
                    alt="about"
                    width="500"
                  />
                </Link>
            </div>
            <ul className="mi-header-menu">
                <li><Link activeClassName="active" to="/"><span>Home</span></Link></li>
                <li><Link activeClassName="active" to="/about"><span>About</span></Link></li>
                <li><Link activeClassName="active" to="/resume"><span>Resume</span></Link></li>
                {/* <li><Link activeClassName="active" to="/portfolios"><span>Portfolios</span></Link></li> */}
                {/* <li><Link activeClassName="active" to="/contact"><span>Contact</span></Link></li> */}
            </ul>
            <p className="mi-header-copyright">&copy; {new Date().getFullYear()} <b>Steven Lambe</b></p>
        </div>
    </nav>
  )
}

export default Header;
