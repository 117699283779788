import React from 'react'

function BackgroundLines(){
  return (
    <div className="mi-bglines">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  )
}

export default BackgroundLines;
